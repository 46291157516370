exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contests-js": () => import("./../../../src/pages/contests.js" /* webpackChunkName: "component---src-pages-contests-js" */),
  "component---src-pages-edu-uw-js": () => import("./../../../src/pages/edu/uw.js" /* webpackChunkName: "component---src-pages-edu-uw-js" */),
  "component---src-pages-edu-wyk-js": () => import("./../../../src/pages/edu/wyk.js" /* webpackChunkName: "component---src-pages-edu-wyk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-js": () => import("./../../../src/pages/music.js" /* webpackChunkName: "component---src-pages-music-js" */),
  "component---src-pages-projects-cf-warrior-js": () => import("./../../../src/pages/projects/cf-warrior.js" /* webpackChunkName: "component---src-pages-projects-cf-warrior-js" */),
  "component---src-pages-projects-clash-royale-api-js": () => import("./../../../src/pages/projects/clash-royale-api.js" /* webpackChunkName: "component---src-pages-projects-clash-royale-api-js" */),
  "component---src-pages-projects-dse-stats-js": () => import("./../../../src/pages/projects/dse-stats.js" /* webpackChunkName: "component---src-pages-projects-dse-stats-js" */),
  "component---src-pages-projects-fascinating-compositions-index-js": () => import("./../../../src/pages/projects/fascinating-compositions/index.js" /* webpackChunkName: "component---src-pages-projects-fascinating-compositions-index-js" */),
  "component---src-pages-projects-fascinating-compositions-privacy-policy-js": () => import("./../../../src/pages/projects/fascinating-compositions/privacy-policy.js" /* webpackChunkName: "component---src-pages-projects-fascinating-compositions-privacy-policy-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-rock-the-criminal-js": () => import("./../../../src/pages/projects/rock-the-criminal.js" /* webpackChunkName: "component---src-pages-projects-rock-the-criminal-js" */),
  "component---src-pages-projects-walfie-gif-dl-js": () => import("./../../../src/pages/projects/walfie-gif-dl.js" /* webpackChunkName: "component---src-pages-projects-walfie-gif-dl-js" */),
  "component---src-pages-projects-word-chain-bot-js": () => import("./../../../src/pages/projects/word-chain-bot.js" /* webpackChunkName: "component---src-pages-projects-word-chain-bot-js" */),
  "component---src-pages-projects-wykoj-js": () => import("./../../../src/pages/projects/wykoj.js" /* webpackChunkName: "component---src-pages-projects-wykoj-js" */)
}

